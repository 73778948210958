.p-overflow-hidden {
  padding-right: 0;
}

body {
  .p-component { // this is not alphabetically sorted on purpose...styles are applyed in the order they show up here and other components may override this
    font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
    font-size: .875rem;
  }

  .p-disabled:not(.p-dropdown-item),
  .p-disabled:not(.p-dropdown-item) * {
    pointer-events: inherit;
  }

  .p-accordion {
    .p-accordion-header {
      margin-bottom: 0;
      color: #3496A1;

      &:focus,
      a:focus {
        box-shadow: none;
      }

      &:not(.p-disabled).p-highlight .p-accordion-header-link,
      &:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        color: inherit;
        background-color: inherit;
        border: 1px solid #c8c8c8;
        border-bottom-width: 0;
        .p-accordion-toggle-icon {
          color: inherit;
        }
      }
    }

    // HACK: this was broken on primenNG 14.1.2 (nested accordions)
    .p-accordion-tab-active .p-toggleable-content:not(.ng-animating) {
      overflow: hidden;
    }
    .p-accordion-tab-active > .p-toggleable-content:not(.ng-animating) {
      overflow: inherit;
    }
    // ---

    .p-accordion-content {
      background-color: transparent;
      border-top-width: 0;
    }
  }

  p-button,
  p-splitbutton,
  a.p-button {
    line-height: initial;
  }

  .p-selectbutton {
    .p-button {
      background-color: white;

      &.p-highlight {
        background-color: #60B6C2;
        border-color: #60B6C2;
      }
    }
  }

  .p-button {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;
    min-height: 37px;
    text-transform: uppercase;

    .p-button-label {
      line-height: normal;
    }

    &.p-button-primary {
      background-color: #60B6C2;
      border-color: #60B6C2;
    }

    &.p-button-outline {
      background-color: transparent;
      border-color: #60B6C2;
      color: #60B6C2;
      &:hover {
        background-color: transparent;
        border-color: #007ad9;
        color: #007ad9;
      }
    }

    &.p-button-secondary {
      color: #333;
      background-color: #f4f4f4;
      border: 1px solid #f4f4f4;
    }

    &.p-button-rounded.p-button-secondary {
      background-color: #FFFFFF;
      border: 1px solid #c8c8c8;
      color: #606060;
    }

    &.p-button-raised.p-button-secondary {
      background-color: #FFFFFF;
      border: 1px solid #DDE1E0;
      color: #606060;
    }

    &.p-button-label,
    &.p-button-text {
      color: #60B6C2;
      background-color: transparent;
      border-color: transparent;
      font-weight: 500;
      letter-spacing: 0.04em;
    }
    &.p-button-label:hover,
    &.p-button-text:hover {
      color: #60B6C2;
      background-color: #DDE1E0;
      border-color: #DDE1E0;
    }
    &.p-button-label:focus,
    &.p-button-text:focus {
      box-shadow: none;
    }

    &.p-button-raised.p-button-info {
      background-color: #60B6C2;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18), 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      border: 0;
      font-weight: 500;
      line-height: normal;
    }

    &.p-button-secondary:enabled:hover {
      background-color: #c8c8c8;
      color: #333;
      border-color: #c8c8c8;
    }
  }

  .p-card {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    .p-card-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
    }
  }

  .p-contextmenu {
    z-index: 2005 !important; // to go over the tooltips
  }

  .p-dialog-mask {
    z-index: 2003 !important;
  }

  .p-dialog {
    max-width: 1080px;
  }
  @media screen and (max-width: 1199px) {
    .p-dialog {
      max-width: 90%;
    }
  }
  .p-dialog {
    z-index: 2003 !important;

    .p-dialog-header {
      background-color: white;
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      position: relative;

      .p-dialog-title {
        color: #46535A;
        font-size: 1.375rem;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .tertiary-action {
        position: absolute;
        top: 0.6rem;
        right: 1.2rem;
        .p-button {
          box-shadow: none !important;
          font-size: 1.1rem;
        }
      }
    }

    .p-dialog-content {
      border: none;
    }

    .p-dialog-footer {
      display: flex;
      flex-direction: row-reverse;
      border: none;
    }
  }

  .p-dropdown,
  .p-dropdown-label,
  .p-dropdown-trigger {
    background-color: transparent;
    background: transparent;
  }

  .p-dropdown.p-dropdown-open.p-focus {
    outline: 0 none;
    box-shadow: 0 0 0 0.2rem #b0bec5 !important;
  }

  .p-dropdown-panel {
    .p-dropdown-items-wrapper {
      max-height: 445px !important;
      max-width: 90vw;

      .p-dropdown-item {
        font-weight: 400;
        padding: 0.375rem 0.75rem;
      }
      .p-dropdown-item-group {
        background: white;
        font-weight: 700;
        padding: 0.375rem 0.75rem;
      }
    }
  }

  .p-inputtext {
    font-family: inherit;
  }

  .p-fileupload {
    .p-fileupload-content {
      min-height: 100px;

      .p-fileupload-files {
        display: block;
        height: 100%;
        > div {
          height: 100%;

          .p-fileupload-row {
            height: 100%;

            > div:nth-of-type(1) {
              max-width: 25%;
              width: inherit;
            }
            > div:nth-of-type(2) {
              width: inherit !important;
            }
          }
        }
      }
    }
  }

  .p-message {
    border-radius: 0;
    margin: 0;

    &.p-message-info {
      background-color: #CBEFFF;
    }

    &.p-message-error {
      background-color: #F0F1ED;
      border: 0 none;
      color: #DC6448;
      .p-message-icon {
        color: #DC6448;
        line-height: 1rem;

        &::before {
          content: '\f06a';
          font-family: 'Font Awesome 6 Free';
          font-weight: 900;
          font-size: 1rem;
          line-height: 1;
        }

        timescircleicon {
          display: none;
        }
      }
    }
  }

  .p-multiselect-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-orderlist-item {
    &.cdk-drag {
      z-index: 2003 !important;
    }
  }

  .p-splitbutton {
    display: flex;
    min-height: 37px;

    .p-splitbutton-menubutton {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.p-button-outline {
      display: inline;

      > .p-button {
        background-color: transparent;
        border: 1px solid #60B6C2;
        border-radius: 3px;
        color: #60B6C2;

        &:enabled:hover {
          background-color: transparent;
          border-color: #007ad9;
          color: #007ad9;
        }
      }

      > .p-splitbutton-menubutton {
        display: none;
      }
    }

    &.p-button-primary > .p-button {
      background-color: white;
      background-color: #60B6C2;
      border-color: #60B6C2;
    }

    &.p-button-secondary > .p-button {
      background-color: white;
      color: #333;
      border-color: #c8c8c8;
      &:enabled:hover {
        background-color: #c8c8c8;
        color: #333;
        border-color: #c8c8c8;
      }
    }

  }

  .p-menu,
  .p-tieredmenu {
    z-index: 2001 !important;
    .p-menuitem {
      .p-menuitem-link:not(.p-disabled) {
        .p-menuitem-icon,
        .p-menuitem-text {
          color: #3496A1;
        }

        &:hover {
          background-color: #F0FAFF;

          .p-menuitem-icon,
          .p-menuitem-text {
            color: #3496A1;
          }
        }
      }
    }
  }

  .p-toast {
    z-index: 2004 !important; // to go over the dialogs
    .p-toast-detail {
      white-space: pre-wrap;
    }

    .p-toast-message {
      &.p-toast-message-info {
        background-color: #CBEFFF;
      }
    }
  }

  .p-togglebutton {
    min-height: 37px;

    &.p-button.p-highlight {
      background-color: #60B6C2;
      border-color: #60B6C2;
      color: white;
    }
  }

  .p-tooltip {
    // pointer-events: none;
    z-index: 2004;
    max-width: 400px;

    .p-tooltip-text {
      white-space: normal;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .p-tree {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;

    .p-tree-container {
      .p-treenode {
        padding: 0.143rem 0;

        .p-treenode-selectable.p-treenode-content {
          line-height: 1.2rem;
        }
        .p-treenode-content-selected:focus {
          outline: none !important;
          box-shadow: none !important;
        }
        .p-tree-toggler-icon,
        .p-treenode-icon {
          // this is the same as setting the node class as 'fa'
          display: inline-block;
          font-family: 'Font Awesome 6 Free';
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          // this is the same as setting the node class as 'fa'
        }
        .p-tree-toggler-icon.pi.pi-fw.pi-chevron-down {
          font-weight: 900; // fas
          &::before {
            content: '\f0d7';
          }
        }
        .p-tree-toggler-icon.pi.pi-fw.pi-chevron-right {
          font-weight: 900; // fas
          &::before {
            content: '\f0da';
          }
        }

        .p-treenode-content {
          .p-tree-toggler {
            color: #a6a6a6 !important;
            min-width: 2rem;
          }
        }
      }
    }
  }
}



.p-splitbutton {
  border-radius: 4px;
}
.p-splitbutton.p-button-raised {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
.p-buttonset.p-splitbutton.p-button-primary {
  .p-button {
    background-color: #60B6C2;
    border-color: #60B6C2;
  }
}

.p-buttonset.p-splitbutton.p-button-secondary {
  border: 1px solid #DDE1E0;

  .p-button {
    background-color: #FFFFFF;
  }
  .p-splitbutton-menubutton {
    color: #606060;
  }
}



.p-inputtext:not(.p-listbox):not(.p-dropdown-label):not(.p-inputtextarea-resizable),
.p-inputtext:not(.p-inputtextarea-resizable),
.p-dropdown,
.p-multiselect {

  min-height: 36px;
  line-height: 36px;

  &:disabled {
    border-bottom: 1px dotted gray;
  }
}
@media all and (-ms-high-contrast:none) {
  .p-inputtext:not(.p-listbox):not(.p-dropdown-label):not(.p-inputtextarea-resizable),
  .p-inputtext:not(.p-inputtextarea-resizable),
  .p-dropdown,
  .p-multiselect {
    box-sizing: content-box; // IE11 HACK
  }
}



.p-datepicker,
.p-dropdown-panel,
.p-multiselect-panel {
  z-index: 2004 !important; // to go over the dialogs when using appendTo="body"
}



.p-dropdown-trigger,
.p-multiselect-trigger {
  border: 0;
}

.p-multiselect {
  .p-highlight {
    color: black;
    background: transparent;
  }
}

.p-float-label {
  margin-top: 1.4rem;
  label {
    white-space: nowrap;
  }
}

.p-float-label > input:focus ~ label,
.p-float-label > input.p-state-filled ~ label,
// .p-float-label > .p-inputwrapper-focus ~ label,
// .p-float-label > .p-inputwrapper-filled ~ label,
.p-float-label > .input:-webkit-autofill ~ label {
  top: -.6rem !important;
  font-size: .8rem !important;
}
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > .p-inputwrapper-filled ~ label {
  top: -.6rem !important;
  font-size: .8rem !important;
}



.p-tabview {
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  .p-tabview-panels {
    border: 1px solid #C6C6C5;
    .p-tabview-panel {
      padding: .5rem;
      overflow-y: auto;
    }
  }
}

.p-menu {
  width: auto;
  .p-menuitem {
    white-space: nowrap;
    .p-menuitem-link.p-menuitem-link-active {
      .p-menuitem-text {
        color: #007ad9;
      }
    }
  }
}

.p-menuitem-link.p-disabled {
  pointer-events: initial;
}

p-fileUpload.invalid {
  .p-fileupload-content {
    border: 1px solid #DC6448;
  }
}



body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
  .p-checkbox-icon {
    vertical-align: inherit;
  }
}
